
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { FiArrowLeftCircle, FiSettings, FiTarget, FiBook, FiRotateCw, FiPackage, FiActivity, FiLogOut, FiLifeBuoy, FiUsers, FiUserCheck, FiHome, FiBriefcase, FiArchive  } from "react-icons/fi";

import defaultLogo from "../../Project/Assets/Images/xcode_icon.png"; 
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentProject, setLogo } from "../../../redux/project/slice";

export default function Sidebar({ menuItems, coreItems, type, project }) {

    const navigate = useNavigate();
    const getIcon = (name) =>
    {
        if(name === "projects")
            return (<FiBriefcase className="h-5 w-5 text-blue-500" />);
        
        if(name === "groups")
            return (<FiPackage className="h-5 w-5 text-blue-500" />);
        
        if(name === "insights")
            return (<FiActivity className="h-5 w-5 text-blue-500" />);
        
        if(name === "iterations")
            return (<FiRotateCw className="h-5 w-5 text-blue-500" />);

        if(name === "helpdesk")
            return (<FiLifeBuoy className="h-5 w-5 text-blue-500" />);

        if(name === "groups")
            return (<FiArchive className="h-5 w-5 text-blue-500" />);

        if(name === "reports")
            return (<FiBook className="h-5 w-5 text-blue-500" />);

        if(name === "dashboard")
            return (<FiHome className="h-5 w-5 text-blue-500" />);

        if(name === "funnels" || name === "experiments")
            return (<FiTarget className="h-5 w-5 text-blue-500" />);

        if(name === "settings")
            return (<FiSettings className="h-5 w-5 text-blue-500" />);

        if(name === "logout")
            return (<FiLogOut className="h-5 w-5 text-blue-500" />);

        if(name === "back")
            return (<FiArrowLeftCircle className="h-5 w-5 text-blue-500" />);

            
        
        return (<FiActivity className="h-5 w-5 text-blue-500" />);
    };

    const onNavigate = (item) => {
        if(item.key === "logout")
        {
            // eslint-disable-next-line no-restricted-globals
            var confirmed = confirm("Please confirm to exit.")
            if(confirmed)
            {
                ReactSession.set("user", null);
                navigate(item.url);
            }
            return;
        }
        navigate(item.url);
    };
  
    return (

      <aside class="sidebar w-64 -translate-x-full transform bg-white p-4 transition-transform duration-150 ease-in md:translate-x-0 md:shadow-md" aria-label="Sidenav">
      <div class="overflow-y-auto py-5 px-3 h-full bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      {type && type === "project" ?<>

      <div class="mb-3">
            <img
              alt="profil"
              src={project && project.Logo ? project.Logo: defaultLogo}
              projectName  class="mx-auto object-cover rounded-md h-14 w-14" />
            <div className="text-center">{project != null ? project.Name: "Loading ..."}</div>
      </div>
      
      </>:<>
 
      
      </>}
      

      <ul class="space-y-2 ">
          {menuItems && menuItems.map((item, index) => (
              <li key={index}>
              <a href={item.url} class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                  {getIcon(item.key)}
                  <span class="ml-3">{item.name}</span>
              </a>
              </li>
          ))}
      </ul>

      <ul class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
          {coreItems && coreItems.map((item, index) => (
              <li key={index}>
              <button onClick={() => onNavigate(item)} class="flex w-max items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                  {getIcon(item.key)}
                  <span class="ml-3">{item.name}</span>
              </button>
              </li>
          ))}
      </ul>


      </div>
      </aside>
    );
  }



