
import { useMatch, useNavigate, useLocation  } from "react-router-dom";
import React, { useState } from 'react';


import { EditTicket } from "./Api/HelpDeskClient";
import useTicketGet from "./Hooks/useTicketGet";
import LoadingControl from '../../components/Core/LoadingControl/LoadingControl';



export default function TicketEdit() {

  const location = useLocation();
  var template =location.pathname.indexOf("helpdesk/ticket") > 0
  ? "/helpdesk/ticket/:ticketId" : "/helpdesk/create";
  const match = useMatch(template);
  var ticketId = match && match.params && match.params.ticketId;
  //======

  const navigate = useNavigate();
  const { ticket, setTicket, loading } = useTicketGet(ticketId);
  const [saving, setSaving ] = useState(false);

  //=========
  const handleOnBlur = e => {
    let elementId = e.target.id;
    let elementName = e.target.name;

    let attributeValue = e.target.value.toString().trim();
    //---
    if(elementId === "customername") setTicket({ ...ticket, CustomerName: attributeValue });
    if(elementId === "customeremail") setTicket({ ...ticket, CustomerEmail: attributeValue });
    //---
    if(elementId === "description") setTicket({ ...ticket, Description: attributeValue });
    if(elementId === "name") setTicket({ ...ticket, Name: attributeValue });
    //---
    if(elementId === "appkey") setTicket({ ...ticket, AppKey: attributeValue });
    if(elementId === "platform") setTicket({ ...ticket, Platform: attributeValue });
    if(elementName === "status") setTicket({ ...ticket, Status: parseInt(attributeValue) });
  }


  const handleSubmit = event => {
      event.preventDefault();
      setSaving(true);
      const response = EditTicket(ticket).then(response => {
        console.log(response.data);
        setSaving(false);
        navigate("/helpdesk");
      });
  }


  return (
    <div className='m-5 w-96'>
      {loading ? <><LoadingControl /></> :<>
      <div component="form">


          <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Customer Name</label>
          <input  
          id="customername"
          name="customername"
          onBlur={handleOnBlur}
          defaultValue={ticket && ticket.CustomerName}
          class="bg-gray-50 my-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>

          <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Customer Email</label>
          <input  
          id="customeremail"
          name="customeremail"
          onBlur={handleOnBlur}
          defaultValue={ticket && ticket.CustomerEmail}
          class="bg-gray-50 my-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>




          <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
          <input  
          id="name"
          name="name"
          onBlur={handleOnBlur}
          defaultValue={ticket && ticket.Name}
          class="bg-gray-50 my-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>




          <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
          <textarea   
          id="description" name="description" rows="6" 
          onBlur={handleOnBlur} defaultValue={ticket && ticket.Description}
          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Leave a comment..."></textarea>





          <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">AppKey</label>
          <input  
          id="appkey"
          name="appkey"
          onBlur={handleOnBlur}
          defaultValue={ticket && ticket.AppKey}
          class="bg-gray-50 my-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>





          <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Platform</label>
          <input  
          id="platform"
          name="platform"
          onBlur={handleOnBlur}
          defaultValue={ticket && ticket.Platform}
          class="bg-gray-50 my-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>




          <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status</label>
          <select id="status" name="status" defaultValue={ticket && ticket.Status} onBlur={handleOnBlur} label="Status" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option value="0">Open</option>
          <option value="1">Closed</option>
          </select>




          <button 
            onClick={handleSubmit}
            class=" block w-full my-4 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">
            {saving ? <>Saving...</> : <>Save</>}
          </button>


      </div>
      </>}

    </div>
  );
}