import axios from 'axios';
import Config from "../../../configurations/config"; //"../../configurations/config";

export function GetFunnels(projectId) 
{
    const apiPayload = { ProjectId : projectId };
    return Exec("funnels/all", apiPayload);
}

export function EditFunnel(funnel) 
{
  const apiPayload = { Model: funnel };
  return Exec("funnels/edit", apiPayload);
}

export function GetFunnel(projectId, funnelId)
{
  const apiPayload =  { FunnelId: funnelId, ProjectId: projectId };


  console.log(apiPayload);
  

  return  Exec("funnels/get", apiPayload);
}


export function GetData(appkey, platform)
{
  var timeframe = "weeks"
  var url = `https://s3.us-west-2.amazonaws.com/app.getproject/projects/${appkey}/funnels/${appkey}-${platform}-${timeframe}.json`
  console.log("url: " + url);
  return  axios.get(url);
}


function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}
