
import * as React from 'react';


import { useCallback, useState, useEffect } from "react";
import Checkbox from '@mui/material/Checkbox';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';



export default function ColumnView({ column, store }) {


      const handleOnChange = e => {

        const columnId= column.Id;
        const attributeName = e.target.id;
        console.log(e.target.checked);
        let attributeValue = e.target.checked;
        console.log(attributeValue);

        console.log(columnId +" "+ attributeName +" "+ attributeValue);
        store.UpdateColumn(columnId, attributeName, attributeValue);

      }

      
      const handleOnBlur = e => {
   
        const columnId= column.Id;
        const attributeName = e.target.id;
        let attributeValue = e.target.value.trim();

        console.log(columnId +" "+ attributeName +" "+ attributeValue);
        store.UpdateColumn(columnId, attributeName, attributeValue);

      }



  return (
    <div>

      <div className="flex" direction="row" spacing={2}>
        <Checkbox color="success" id="enabled" onChange={handleOnChange} 
        checked={column.Enabled} />

        <Checkbox color="success" id="conversion" onChange={handleOnChange} 
        checked={column.Conversion} />

        <input type="text"
              id="name" name="name" onBlur={handleOnBlur} defaultValue={column.Name}
              class="bg-gray-50 my-1 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              placeholder="Platform" required />


        <IconButton  onClick={(e) => store.DeleteColumn(column.Id)} aria-label="delete">
           <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}