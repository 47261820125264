
import * as React from 'react';

import { Route, Routes, useLocation, useNavigate, useMatch } from "react-router-dom";

import useFunnelData from "./Hooks/useFunnelData";
import FunnelTable from './Components/FunnelTable';
import FunnelEditor from './Components/FunnelEditor';
import FunnelStore from "./Store/FunnelStore";
import useFunnelGet from "./Hooks/useFunnelGet";
import useFunnelSave from "./Hooks/useFunnelSave";
import LoadingControl from '../../components/Core/LoadingControl/LoadingControl';


import { Funnel, Bar } from 'funnel-react';


const funnelData = [
  {
      "_id": "5de52b4ac4275a463f912042",
      "item": "accepted",
      "label": "Aceptados",
      "percent": 0,
      "quantity": 100
  },
  {
      "_id": "5de52b4ac4275a463f912041",
      "item": "visitors",
      "label": "Visitantes",
      "percent": 0,
      "quantity": 90
  },
  {
      "_id": "5de52b4ac4275a463f912040",
      "item": "postulants",
      "label": "Postulantes",
      "percent": 0,
      "quantity": 80
  },
  {
      "_id": "5de52b4ac4275a463f91203f",
      "item": "inprocess",
      "label": "En proceso",
      "percent": 0,
      "quantity": 50
  },
  {
      "_id": "5de52b4ac4275a463f91203e",
      "item": "finalists",
      "label": "Finalistas",
      "percent": 0,
      "quantity": 10
  }
]



export default function FunnelEx() {

  const {
    params: { projectId, funnelId },
  } = useMatch("/project/:projectId/funnel/:funnelId");



  const { meta, setMeta, loading } = useFunnelGet(projectId, funnelId);

  const allKey = meta?.AppKey;
  const platform = meta?.Platform;
  const { data } = useFunnelData(allKey, platform);

  const { funnelSave, saving } = useFunnelSave(meta);

  let store = new FunnelStore(meta, setMeta);



  return (
    <div className="m-5">

      <button 
            onClick={funnelSave} fullWidth type="submit"
            className="fullWidth bg-blue-500 hover:bg-blue-700 text-white font-bold py-2border border-blue-700 rounded">
            {saving ? <>Saving ...</> : <>Save</>}
      </button>
    

      <div className='m-2'>

        {loading ? <><LoadingControl /></> :<>

        <FunnelEditor meta={meta} store={store} />

        </>}

        {loading ? <><LoadingControl /></> :<>
        <FunnelTable showTags={true} save={funnelSave} meta={meta} data={data} />
        </>}
  
      </div>
   
    </div>
  );


}