import * as React from 'react';





import ColumnView from "./ColumnView";

export default function FunnelEditor({ meta, store, save }) {

    const handleOnBlur = e => {
        const attributeName = e.target.id;
        let attributeValue = e.target.value.trim();
        console.log(attributeName +" "+ attributeValue);
        store.Update(attributeName, attributeValue);
      }

  return (
    <div>

    <div className="m-2">

    <div class="mb-6">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
            <input type="text"
              id="name" name="name" onBlur={handleOnBlur} defaultValue={meta && meta.Name}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              placeholder="Name" required />
    </div> 


    <div class="mb-6">
            <label for="appkey" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">App Key</label>
            <input type="text"
              id="appkey" name="appkey" onBlur={handleOnBlur} defaultValue={meta && meta.AppKey}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              placeholder="App Key" required />
    </div> 



    <div class="mb-6">
            <label for="platform" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Platform</label>
            <input type="text"
              id="platform" name="platform" onBlur={handleOnBlur} defaultValue={meta && meta.Platform}
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              placeholder="Platform" required />
    </div> 

    


    <h4>Columns</h4>

    {meta.Metrics && meta.Metrics.filter(t => t.RecordState === 0).map((metric) => (
         <ColumnView key={metric.Id} column={metric}  store={store} />
    ))}




    <button 
          onClick={(e) => store.AddColumn()}
          className="bg-white hover:bg-gray-100 text-gray-800 py-2 px-4 border border-gray-400 rounded shadow">
            + Line Item
    </button>


    </div>

  
    </div>


  );
}