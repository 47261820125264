
import * as React from 'react';



import { useCallback, useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate, useMatch } from "react-router-dom";
import LoadingControl from '../../components/Core/LoadingControl/LoadingControl';

import ItemView from "./Components/ItemView";
import useTicketList from "./Hooks/useTicketList";


export default function TicketList() {



  const location = useLocation();
  var template =location.pathname.indexOf("project/") > 0
  ? "/project/:projectId/:appId" : "/helpdesk";
  const match = useMatch(template);
  var projectId = match && match.params && match.params.projectId;


  const { data, loading } = useTicketList(projectId);

  return (
    <div className='m-5 max-w-xl'>
      <a href={`/helpdesk/create`} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Create</a>
      <div>
        {loading ? <><LoadingControl /></> :
        <>
        <div>Tickets - {data.length}</div>

        <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
        <ul class="flex flex-col divide-y w-full">
        {data.map((ticket) => (
         <ItemView ticket={ticket} key={ticket.Id} />
        ))}
        </ul>
        </div>
        
        </>}
      </div>
    </div>
  );


}