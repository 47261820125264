import * as React from "react";


import { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useMatch,
} from "react-router-dom";
import TableView from "../Components/TableView";
import useReportSales from "../Hooks/useReportSales";

import SalesView from "./SalesView";
import SalesEditor from "./Editor/SalesEditor";
import LoadingControl from "../../../components/Core/LoadingControl/LoadingControl";

export default function ReportSales() {
  const reportSettings = {

    Groups: [
      { Name: "Business Products", AppKeys:[ "oshasafety", "hazmat", "appiconmaker", "siteaudit", "quickinventory"  ] },
      { Name: "Mini Products", AppKeys:[ "mlgsounds", "vinesounds", "beethoven", "bach", "mozart", "handel", "puccini"  ] }
    ],

    Visible: true,
    Products: [
      { AppKey: "appiconmaker", Platform: "web", Price: 0.1 },
      //=========
      { AppKey: "oshasafety", Platform: "ios", Price: 13.99 },
      { AppKey: "oshasafety", Platform: "droid", Price: 13.99 },
      //=========
      { AppKey: "hazmat.spanish", Platform: "ios", Price: 13.99 },
      { AppKey: "hazmat.spanish", Platform: "droid", Price: 9.99 },
      { AppKey: "hazmat", Platform: "ios", Price: 13.99 },
      { AppKey: "hazmat", Platform: "droid", Price: 13.99 },
      //==========
      { AppKey: "mlgsounds", Platform: "ios", Price: 1.99 },
      { AppKey: "vinesounds", Platform: "ios", Price: 1.99 },
      { AppKey: "beethoven", Platform: "ios", Price: 1.99 },
      { AppKey: "bach", Platform: "ios", Price: 1.99 },
      { AppKey: "mozart", Platform: "ios", Price: 1.99 },
      { AppKey: "handel", Platform: "ios", Price: 1.99 },
      { AppKey: "puccini", Platform: "ios", Price: 1.99 },
      //========
      { AppKey: "quickinventory", Platform: "ios", Price: 9.99 },
      { AppKey: "siteaudit", Platform: "ios", Price: 9.99 },
      { AppKey: "siteaudit", Platform: "droid", Price: 9.99 },
      { AppKey: "spamusicboard", Platform: "ios", Price: 1.99 },
    
      //========
      { AppKey: "hourstracker", Platform: "ios", Price: 1.99 }, // TODO: differet subs
    ],
  };

  const { data, loading } = useReportSales();

  return (
    <div className="text-base text-left">
   
        {loading ? (
          <><LoadingControl /></>
        ) : (
          <>
            {/* <SalesEditor data={data} /> 
             {/* <TableView data={data} /> */}
            <SalesView data={data} settings={reportSettings} />
          </>
        )}

    </div>
  );
}
