import { v4 as uuid } from 'uuid';


export default class FunnelStore {

    constructor(data, setData) {
      this.data = data;
      this.setData = setData;
    }


    Update(attributeName, attributeValue)
    {
      console.log("update "+attributeName+" "+attributeValue);


      if(attributeName === "name")
      {
      var newModel = {
        ...this.data,
        Name: attributeValue
      };
      this.setData(newModel); 
      console.log(newModel);
      }


      if(attributeName === "appkey")
      {
      var newModel = {
        ...this.data,
        AppKey: attributeValue
      };
      this.setData(newModel); 
      console.log(newModel);
      }

      if(attributeName === "platform")
      {
      var newModel = {
        ...this.data,
        Platform: attributeValue
      };
      this.setData(newModel); 
      console.log(newModel);
      }

      
    }


    UpdateColumn(id, attributeName, attributeValue)
    {
      console.log("update "+attributeName+" "+attributeValue);
      var newModel = {
        ...this.data,
        Metrics: this.data.Metrics.map(item => {
          if (item.Id !== id) {
            return item
          };

          if(attributeName === "enabled")
          return {
            ...item,
            Enabled: attributeValue
          };

          if(attributeName === "conversion")
          return {
            ...item,
            Conversion: attributeValue
          };

          if(attributeName === "name")
          return {
            ...item,
            Name: attributeValue
          };

      
          return item;
        })
      };
      this.setData(newModel); 
      console.log(newModel);
    }



    AddColumn()
    {
        if(!this.data.Metrics)
        {
            var emptyCols = {
                ...this.data,
                Metrics: []
            };
            this.setData(emptyCols);
        }

        console.log("add column");
        var newModel = {
        ...this.data,
        Metrics: [
          ...this.data.Metrics,
          {
            Id: uuid(),
            Name: "",
            Enabled: true,
            Conversion: false,
            RecordState: 0
          }
        ]
      }
      this.setData(newModel);
    };


    DeleteColumn(id)
    {
        console.log("delete - "+id);
        console.log(this.data);

        var newModel = {
          ...this.data,
          Metrics: this.data.Metrics.filter(item => item.Id !== id)
        };
        this.setData(newModel);  
    };
  
  }