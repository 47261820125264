
import * as React from 'react';

import ItemView from "./ItemView";
import useInsightList from "./Hooks/useInsightList";
import LoadingControl from '../../components/Core/LoadingControl/LoadingControl';

export default function InsightList({ project }) {

  const { data, loading, error } = useInsightList(project);

  return (
    <div className='m-5 w-full'>
      <div>
        {loading ? <><LoadingControl /></> :<>
        <div>Insights</div>

        <div class="flex flex-col  bg-white rounded-lg shadow">
        <ul class="">
        {data.map((insight) => (
         <ItemView item={insight} key={insight.Id} />
        ))}
        </ul>


        </div>
        </>}
      </div>
    </div>
  );
}
